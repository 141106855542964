import React, { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox"; // Install this using `npm install react-image-lightbox`
import "react-image-lightbox/style.css";
import { useTranslation } from "react-i18next";

const Gallery = () => {
    const { i18n, t } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };
    const [activeTab, setActiveTab] = useState("photos");
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [lightboxImages, setLightboxImages] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // Sample data for photos and videos
    const photosData = [
        {
            eventName: "Spiritual Retreat",
            date: "2024-10-20",
            photos: [
                "../../images/new-pics/IMG_0872.JPG",
                "../../images/new-pics/IMG_0860.JPG",
                "../../images/new-pics/IMG_0858.JPG",
                "../../images/new-pics/IMG_0853.JPG",
                "../../images/new-pics/IMG_0854.JPG",
                "../../images/new-pics/IMG_0855.JPG",
            ],
        },

        {
            eventName: "2023 Fasting Prayer",
            date: "2023-1012-07",
            photos: [
                "../../images/2023-fastings/564B1934.JPG",
                "../../images/2023-fastings/564B1920.JPG",
                "../../images/2023-fastings/564B1765.JPG",
                "../../images/2023-fastings/564B1762.JPG",
                "../../images/2023-fastings/564B1769.JPG",
                "../../images/2023-fastings/564B1753.JPG",
            ],
        },
       
        
    ];


    const youtubeVideos = [
        {
            eventName: "Messages",
            videos: [
                "https://www.youtube.com/embed/0moaBRQdHZI?si=M_Dn6EedJDtLgnXV",
                "https://www.youtube.com/embed/ok7IOraz70w?si=BAmu26BFPeIbt6PP",
                "https://www.youtube.com/embed/dLZG3vK_nsM?si=FTJtDu1FHbtkMVc6",

            ]
        },
        {
            eventName: "Meetings Highlights",
            videos: [
                "https://www.youtube.com/embed/mLw3u_bDxMw?si=6xrelbu6kAQAJUg7",
                "https://www.youtube.com/embed/I_iCeYRqt7E?si=KLnJ6ltj1CxRIrgx",
                "https://www.youtube.com/embed/Lplx4x5UBao?si=mIDrjpqnbSeAKjkb",
                "https://www.youtube.com/embed/n64Pj9YSFlA?si=eeKr0SVoxRyTGJks",
                "https://www.youtube.com/embed/FuS_0InWURo?si=nJravBQECagWvCKQ",
            ]
        },
        {
            eventName: "Charity",
            videos: [
                "https://www.youtube.com/embed/U1OD5skEWuA?si=AknUHPZ10_PgqT4m",
                "https://www.youtube.com/embed/n64Pj9YSFlA?si=m3rwnx1sRTzpcQri",
            ]
        }

    ]; // Replace with links from your channel

    // Open Lightbox with event images
    const openLightbox = (images, startIndex) => {
        setLightboxImages(images);
        setCurrentImageIndex(startIndex);
        setIsLightboxOpen(true);
    };

    useEffect(() => {
        if (isLightboxOpen && lightboxImages.length > 0) {
            setCurrentImageIndex(0);
        }
    }, [isLightboxOpen, lightboxImages]);

    return (
        <div className=" bg-[#faebdb] min-h-screen text-gray-800">
            <div className="container mx-auto px-6 py-12">
                <div className="flex justify-center space-x-6 mb-4">
                    <button
                        onClick={() => setActiveTab("photos")}
                        className={`inline-flex justify-between items-center px-4 py-2 mb-7 text-sm text-gray-100 bg-[#1c1f65] rounded-full ${activeTab === "photos"
                            ? "bg-[#1c1f65] text-gray-100"
                            : "bg-gray-700 hover:bg-[#1c1f65] hover:text-gray-100"
                            }`}
                    >
                        Photos
                    </button>
                    <button
                        onClick={() => setActiveTab("videos")}
                        className={`inline-flex justify-between items-center px-4 py-2 mb-7 text-sm text-gray-100 bg-[#1c1f65] rounded-full ${activeTab === "videos"
                            ? "bg-[#1c1f65] text-gray-100"
                            : "bg-gray-700 hover:bg-[#1c1f65] hover:text-gray-100"
                            }`}
                    >
                        Videos
                    </button>
                </div>

                {/* Gallery Content */}
                {activeTab === "photos" && (
                    <div className="container mx-auto">
                        {photosData.map((event, index) => (
                            <div key={index} className="mt-8">
                                <h2 className="text-2xl font-semibold mb-4">{event.eventName}</h2>
                                <p className="text-sm text-gray-500 px-4">{event.date}</p>
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                                    {event.photos.map((photo, idx) => (
                                        <div key={idx} className="bg-white rounded-lg shadow-md overflow-hidden">
                                            <div
                                                key={idx}
                                                className="relative group overflow-hidden rounded-lg cursor-pointer"
                                                onClick={() => openLightbox(event.photos, idx)}
                                            >
                                                <img
                                                    src={photo}
                                                    alt={`Photo ${idx + 1}`}
                                                    className="w-full h-48 object-cover transform group-hover:scale-105 transition-all duration-300"
                                                />
                                                <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-0 transition-all duration-300"></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>

                )}

                {activeTab === "videos" && (
                    <div className="container mx-auto">
                        {youtubeVideos.map((event, eventIndex) => (
                            <div key={eventIndex} className="mt-8">
                                <h2 className="text-2xl font-semibold mb-4">{event.eventName}</h2>
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                                    {event.videos.map((video, videoIndex) => (
                                        <div key={videoIndex} className="bg-white rounded-lg shadow-md overflow-hidden">
                                            <iframe
                                                src={video}
                                                className="w-full h-64"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                                title={`YouTube Video ${eventIndex + 1}-${videoIndex + 1}`}
                                            ></iframe>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {/* Lightbox */}
                {isLightboxOpen && (
                    <Lightbox
                        mainSrc={lightboxImages[currentImageIndex]}
                        nextSrc={lightboxImages[(currentImageIndex + 1) % lightboxImages.length]}
                        prevSrc={lightboxImages[(currentImageIndex + lightboxImages.length - 1) % lightboxImages.length]}
                        onCloseRequest={() => setIsLightboxOpen(false)}
                        onMovePrevRequest={() =>
                            setCurrentImageIndex((currentImageIndex + lightboxImages.length - 1) % lightboxImages.length)
                        }
                        onMoveNextRequest={() =>
                            setCurrentImageIndex((currentImageIndex + 1) % lightboxImages.length)
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default Gallery;
