import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faInstagram, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import ChurchServiceDetails from "../components/churchServiceDetails";

const Home = () => {

    const { i18n, t } = useTranslation();
    const assetBaseUrl = process.env.ASSET_BASE_URL;
    const divStyle = {
        backgroundImage: 'url(../../images/church.jpg)', // Path to your image
        backgroundSize: 'cover', // Stretch the image to cover the container
        backgroundRepeat: 'no-repeat', // Prevent repeating
        backgroundPosition: 'center', // Center the image
        width: '100vw', // Full width of the viewport
        height: '70vh', // Full height of the viewport
    };

    return (
        <div>
            <div className="bg-img" style={divStyle}>
                {/* <section className="">
                    <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
                        <a href="#" className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-100 bg-[#1c1f65] rounded-full">
                            <span className="text-xs bg-primary-600 rounded-full  px-4 py-1.5 mr-3">Blessing to you</span> <span className="text-sm font-medium">Praise the lord</span>
                            <svg className="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                        </a>
                        <h1 className="mb-4 text-3xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">{t('welcome')} <br /> {t('churchName')}</h1>
                        <p className="mb-8 text-md font-normal text-gray-300 leading-5 lg:text-xl sm:px-16 xl:px-48">{t("subtittle")} </p>
                       
                    </div>
                </section> */}
                <section className="text-center pt-8">
                    <div className="py-8 px-4 mx-auto max-w-screen-xl">
                        <a
                            href="#"
                            className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-100 bg-[#1c1f65] rounded-full"
                        >
                            <span className="text-xs bg-primary-600 rounded-full px-4 py-1.5 mr-3">
                                Blessing to you
                            </span>
                            <span className="text-sm font-medium">Praise the lord</span>
                            <svg
                                className="ml-2 w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </a>
                        <h1
                            className="relative text-3xl md:text-5xl lg:text-6xl font-extrabold tracking-tight leading-none text-center text-[#1c1f65] bg-white/60 animate-slideIn "
                            style={{ top: "150px", paddingTop:"10px", paddingBottom:"20px" }}
                        >
                            {t('welcome')} <br /> {t('churchName')}
                        </h1>

                        {/* <p className="mb-8 text-md font-normal text-gray-300 leading-5 lg:text-xl sm:px-16 xl:px-48">
                            {t('subtittle')}
                        </p> */}
                    </div>
                </section>

                {/* Bottom Section */}
                {/* <footer className="bg-opacity-50 text-center p-4 bg-gray-800">
                    <h2 className="text-2xl font-bold text-white">{t('churchName')}</h2>
                </footer> */}
            </div >
            <section className="bg-white">
                <div className="container mx-auto px-4 py-20 grid lg:grid-cols-2 items-center gap-10">
                    <div className="relative">
                        <img className="w-full rounded-xl mr-10" src="../../images/KishoreBabuGaru.jpg" />
                    </div>
                    <div className="text-center lg:text-left">
                        <a href="#" className="inline-flex justify-between items-center px-4 py-2 mb-7 text-sm text-gray-100 bg-[#1c1f65] rounded-full">
                            <span className="text-sm font-medium">{t('welcomeMessage.greetingLine')}
                            </span>
                        </a>
                        <h1 className="text-2xl font-extrabold tracking-tight leading-none text-[#eb1b23]">{t("aboutLeader.title")}</h1>
                        <h1 className="text-3xl font-extrabold tracking-tight leading-none text-[#2d3092] md:text-5xl "> {t("aboutLeader.name")}</h1>
                        <h1 className="mb-4 mt-2 text-xl font-extrabold tracking-tight leading-none text-gray-900">{t("aboutLeader.designation")}, {t("churchName")}</h1>

                        <p className="mb-8 text-md font-normal text-gray-900 leading-5 lg:text-xl">{t("aboutLeader.about")} </p>
                        <a href="https://youtu.be/dLZG3vK_nsM?si=iH_i_6vNsriXSwVN" target="_blank" className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-900 ">
                            <svg className="mr-2 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path></svg>
                            Watch video
                        </a>
                    </div>
                </div>
            </section>
            <section className="bg-[#eb1b23] bg-opacity-25">
                <div className="container mx-auto px-4 py-20 grid lg:grid-cols-2 items-center gap-10">
                    <div className="text-center lg:text-left">
                        <a href="#" className="inline-flex justify-between items-center px-4 py-2 mb-7 text-sm text-gray-100 bg-[#632426] rounded-full">
                            <span className="text-sm font-medium">{t('welcomeMessage.greetingLine')}
                            </span>
                        </a>
                        <h1 className="text-3xl font-extrabold tracking-tight leading-none text-[#632426] md:text-5xl "> {t('churchName')}</h1>
                        <h1 className="mb-4 mt-2 text-xl font-extrabold tracking-tight leading-none text-gray-900"> {t('aboutLeader.name')}</h1>

                        <p className="mb-8 text-md font-normal text-gray-900 leading-5 lg:text-xl ">{t("aboutChurch")}</p>
                        <a href="https://youtu.be/n64Pj9YSFlA?si=oB0PCodfmqu16OhK" target="_blank" className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-[#632426] rounded-lg border border-[#632426] ">
                            <svg className="mr-2 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path></svg>
                            Watch video
                        </a>
                    </div>
                    <div className="relative">
                        <img className="w-full rounded-xl mr-10" src="../../images/prayerHands.jpg" />
                    </div>

                </div>
            </section>
            <section className="bg-[#faebdb]">
                <div className="container mx-auto px-4 py-20  gap-10">
                    <div className="text-center">

                        <h1 className="text-3xl font-extrabold tracking-tight leading-none text-[#2d3092] md:text-5xl ">{t("aboutInfo.title")}</h1>
                        <h1 className="mb-4 mt-2 text-xl font-extrabold tracking-tight leading-none text-gray-900">{t("aboutInfo.subTitle")}</h1>
                        <h1 className="text-3xl font-extrabold tracking-tight leading-none text-gray-900">12000 +
                            <span> {t("aboutInfo.miniTitle")}</span></h1>
                        <div className="grid grid-cols-2 gap-5 max-w-2xl mx-auto mt-8">
                            <div className="px-4 py-10 bg-white border border-dashed border-gray-800 rounded-lg">
                                <h2 className="text-3xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl">1.5M+</h2>
                                <h1 className="mt-2 text-xl font-semibold tracking-tight leading-none text-gray-900">{t("aboutInfo.youtubeOutreach")}</h1>
                            </div>
                            <div className="px-4 py-10 bg-white border border-dashed border-gray-800 rounded-lg">
                                <h2 className="text-3xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl">30+</h2>
                                <h1 className="mt-2 text-xl font-semibold tracking-tight leading-none text-gray-900">{t("aboutInfo.churches")}</h1>
                            </div>
                        </div>

                    </div>
                    <div className="relative">
                        <img className="h-60 mx-auto" src="https://www.churchleadership.com/wp-content/uploads/2021/10/what-might-2020-census-mean-churches-4.png" />
                    </div>

                </div>
            </section>
            {/* <section className="bg-[#2d3092] bg-opacity-85">
                <div className="container mx-auto px-4 py-20  gap-10">
                    <div className="text-center">

                        <h1 className="text-3xl font-extrabold tracking-tight leading-none text-[#2d3092] md:text-5xl ">About Calvary Temple</h1>
                        <h1 className="mb-4 mt-2 text-xl font-extrabold tracking-tight leading-none text-gray-900">We're a family of</h1>
                        <h1 className="text-3xl font-extrabold tracking-tight leading-none text-gray-900">300,000 +
                            <span> members</span></h1>
                        <div className="grid grid-cols-2 gap-5 max-w-2xl mx-auto mt-8">
                            <div className="px-4 py-10 bg-white border border-dashed border-gray-800 rounded-lg">
                                <h2 className="text-3xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl">50M+</h2>
                                <h1 className="mt-2 text-xl font-semibold tracking-tight leading-none text-gray-900">TV Outreach</h1>
                            </div>
                            <div className="px-4 py-10 bg-white border border-dashed border-gray-800 rounded-lg">
                                <h2 className="text-3xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl">10+</h2>
                                <h1 className="mt-2 text-xl font-semibold tracking-tight leading-none text-gray-900">Church's</h1>
                            </div>
                        </div>

                    </div>
                    <div className="relative">
                        <img className="h-60 mx-auto" src="https://www.churchleadership.com/wp-content/uploads/2021/10/what-might-2020-census-mean-churches-4.png" />
                    </div>

                </div>
            </section> */}

            <ChurchServiceDetails />

            <section className="bg-[#2d3092]">
                <div className="container mx-auto px-4 py-20 gap-10">
                    <div className="text-center">
                        <h1 className="mb-4 text-3xl font-extrabold tracking-tight leading-none text-white md:text-5xl">
                            {t("socialMedia.title")}
                        </h1>
                        <p className="mb-8 text-md font-normal text-gray-300 leading-5 lg:text-xl sm:px-16 xl:px-48">
                            {t("socialMedia.info")}
                        </p>
                    </div>
                    <div className="grid grid-cols-5 gap-4 max-w-2xl mx-auto">
                        <div className="flex justify-center items-center p-4 rounded bg-red-600 shadow">
                            <a href="https://www.youtube.com/channel/UCOAS0xznZ6jKBWkmhmyOW3g" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faYoutube} className="w-12 h-12 text-white" />
                            </a>
                        </div>
                        <div className="flex justify-center items-center p-4 rounded bg-pink-600 shadow">
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} className="w-12 h-12 text-white" />
                            </a>
                        </div>
                        <div className="flex justify-center items-center p-4 rounded bg-blue-600 shadow">
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} className="w-12 h-12 text-white" />
                            </a>
                        </div>
                        <div className="flex justify-center items-center p-4 rounded bg-blue-400 shadow">
                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faXTwitter} className="w-12 h-12 text-white" />
                            </a>
                        </div>
                        <div className="flex justify-center items-center p-4 rounded bg-blue-700 shadow">
                            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} className="w-12 h-12 text-white" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>


        </div >
    )
}
export default Home

