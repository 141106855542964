import React from "react";
import { useTranslation } from "react-i18next";

const Contact = () => {

    const {t} = useTranslation();

    return (
        <div>
            <section className="bg-red-500">
                <div className="bg-img" >
                    <section className="bg-[#2d3092]">
                        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
                            <a href="#" className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-100 bg-[#1c1f65] rounded-full">
                                <span className="text-xs bg-primary-600 rounded-full  px-4 py-1.5 mr-3">Blessing to you</span> <span className="text-sm font-medium">Connect with us</span>
                                <svg className="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                            </a>
                            <h1 className="mb-4 text-3xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">{t("contactUs.title")}</h1>
                            <p className="mb-8 text-md font-normal text-gray-300 leading-5 lg:text-xl sm:px-16 xl:px-48">{t("contactUs.message")}</p>
                        </div>
                    </section>
                </div>
            </section>
            <section>
                <div className="py-8 px-4 max-w-7xl mx-auto text-center lg:py-16 lg:px-12">
                    <div className="grid md:grid-cols-2 gap-8">
                        {/* Contact Form - Left Side */}
                        <div className="text-left">
                            <label className="block mb-2">
                                <p>Name</p>
                                <input type="text" className="p-3 text-gray-900 w-full bg-gray-50 rounded border" placeholder="Enter Name" />
                            </label>
                            <label className="block mb-2">
                                <p>Email Address</p>
                                <input type="text" className="p-3 text-gray-900 w-full bg-gray-50 rounded border" placeholder="Enter Email" />
                            </label>
                            <label className="block mb-2">
                                <p>Mobile Number</p>
                                <input type="text" className="p-3 text-gray-900 w-full bg-gray-50 rounded border" placeholder="Enter Mobile Number" />
                            </label>
                            <label className="block mb-2">
                                <p>Message</p>
                                <textarea rows={5} className="w-full p-3 text-gray-900 bg-gray-50 rounded border" placeholder="Enter issue in (600 words)"></textarea>
                            </label>
                            <button className="mt-4 inline-flex w-full justify-center items-center p-5 text-base font-medium text-center text-white rounded-lg border border-gray-100 bg-[#2d3092]">
                                Submit
                            </button>
                        </div>

                        {/* Contact Information - Right Side */}
                        <div className="text-left">
                            <h2 className="text-xl text-gray-900 font-bold tracking-wide uppercase pb-3 libre-font">Get in touch</h2>
                            <h3 className="text-gray-900 font-bold text-base pb-2">Address</h3>
                            <p className="pb-2">{t("address.village")},<br /> {t("address.city")},<br /> {t("address.district")},<br /> {t("address.state")} {"-"} {t("address.pincode")}</p>
                            <div className="flex items-center flex-wrap pb-4 gap-4">
                                <p className="flex items-center whitespace-nowrap">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
                                    </svg>
                                    +91 7416910979
                                </p>
                                {/* <p className="flex items-center whitespace-nowrap">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
                                    </svg>
                                    +91 7416910979
                                </p> */}
                                <p className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                                    </svg>
                                    nbgc1717@gmail.com
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Google Map */}
                    <div className="mt-8">
                        <iframe className="h-96 w-full" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3826.7076607399144!2d80.73314147418782!3d16.43966702941647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35fc110c1e6085%3A0x7272f1998d3f61c2!2sNEW%20BETHESDA%20GOSPEL%20CENTRE!5e0!3m2!1sen!2sin!4v1730045245110!5m2!1sen!2sin" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                    </div>
                </div>
            </section>

        </div>
    )
}
export default Contact