import React from "react";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  const divStyle = {
    backgroundImage: 'url()',
    backgroundSize: 'cover',
    width: '100vw',
  };

  return (
    <div style={divStyle}>
      <section className="relative w-full min-h-screen overflow-hidden">
        {/* Content Section */}
        <div className="container mx-auto px-4 py-20 relative z-10 grid lg:grid-cols-2 items-center gap-10">
          {/* Image Section (removed as requested) */}
          <div className="max-h-[450px] overflow-hidden rounded-xl">
            <img
              className="w-full"
              src="../../images/kishoreannayaSpeech.JPG" // Replace with the actual image path if needed
              alt="Pastor Kishore Babu Garu"
            />
          </div>


          {/* Text Section */}
          <div className="text-center lg:text-left text-gray-900">
            <a href="#" className="inline-flex justify-between items-center px-4 py-2 mb-7 text-sm text-gray-100 bg-[#1c1f65] rounded-full">
              <span className="text-sm font-medium">{t('welcomeMessage.greetingLine')}
              </span>
            </a>
            <h1 className="text-3xl font-extrabold tracking-tight leading-none text-[#eb1b23]">
              {t("churchName")}
            </h1>
            <p className="my-4 text-lg font-normal leading-6 lg:text-xl">
              {t("aboutChurch")}
            </p>
            <p className="text-lg font-normal leading-6 lg:text-xl bg-white text-gray-900 p-4 rounded-md shadow-md">
              {t("aboutUs.churchEstablished")}
            </p>
          </div>
        </div>
      </section>

      <section className="bg-[#faebdb]">
        <div className="container mx-auto px-4 py-20 text-center">
          <h1 className="text-3xl font-extrabold tracking-tight leading-none text-[#2d3092] md:text-5xl">{t("aboutUs.teamTitle")}</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-8">
            <div className="px-4 py-10 bg-white border border-dashed border-gray-800 rounded-lg">
              <img className="w-full rounded-md" src="../../images/Speaker-001.png" alt={t("aboutUs.teamMember1Name")} />
              <h2 className="text-2xl font-extrabold tracking-tight leading-none text-gray-900 mt-4">{t("aboutUs.teamMember1Name")}</h2>
              <p className="mt-2 text-md font-normal text-gray-900">{t("aboutUs.teamMember1Role")}</p>
              <p className="mt-4 text-sm text-gray-700">Br. Uday Chowdary is a passionate worship leader and assistant pastor at New Bethesda Gospel Church, with over 15 years of experience in ministry. Known for his heartfelt worship and inspiring sermons, he is dedicated to guiding his congregation towards a deeper relationship with Christ and actively supporting the community through outreach programs.</p>
            </div>
            <div className="px-4 py-10 bg-white border border-dashed border-gray-800 rounded-lg">
              <img className="w-full rounded-md" src="../../images/Speaker-002.png" alt={t("aboutUs.teamMember2Name")} />
              <h2 className="text-2xl font-extrabold tracking-tight leading-none text-gray-900 mt-4">{t("aboutUs.teamMember2Name")}</h2>
              <p className="mt-2 text-md font-normal text-gray-900">{t("aboutUs.teamMember2Role")}</p>
              <p className="mt-4 text-sm text-gray-700">Sister Esther is a dynamic and inspiring preacher with a deep passion for sharing God's word. With over a decade of experience in ministry, she is dedicated to empowering women and guiding her congregation towards spiritual growth and a stronger faith in Christ.</p>
            </div>
            <div className="px-4 py-10 bg-white border border-dashed border-gray-800 rounded-lg">
              <img className="w-full rounded-md" src="../../images/Speaker-003.png" alt={t("aboutUs.teamMember3Name")} />
              <h2 className="text-2xl font-extrabold tracking-tight leading-none text-gray-900 mt-4">{t("aboutUs.teamMember3Name")}</h2>
              <p className="mt-2 text-md font-normal text-gray-900">{t("aboutUs.teamMember3Role")}</p>
              <p className="mt-4 text-sm text-gray-700">Pastor Ruth Amma Garu is a deeply devoted and experienced minister with over 40 years of service in the ministry. Her unwavering faith and dedication have been a source of inspiration and guidance for countless individuals. Known for her compassionate leadership and profound wisdom, she has tirelessly worked to spread the message of God’s love and grace, empowering others to grow in their spiritual journey.</p>
            </div>
          </div>
        </div>
      </section>


      {/* <section className="bg-[#eb1b23] bg-opacity-25">
        <div className="container mx-auto px-4 py-20 text-center">
          <h1 className="text-3xl font-extrabold tracking-tight leading-none text-[#632426] md:text-5xl">{t("aboutUs.joinUsTitle")}</h1>
          <p className="mb-8 text-md font-normal text-gray-900 leading-5 lg:text-xl">{t("aboutUs.joinUsMessage")}</p>
          <a href="#" className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-[#632426] rounded-lg border border-[#632426] ">
            {t("aboutUs.getInvolved")}
          </a>
        </div>
      </section> */}
    </div>
  );
};

export default About;
