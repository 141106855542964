import React, {useState} from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Header = () => {

    const { i18n, t } = useTranslation();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen); // Toggle the state
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false); // Close the menu
    };

    return (
        <section className="bg-white p-4">
            <div class="container mx-auto flex items-center justify-between">
            <picture>
                <img class="h-16" src="../../images/nbgc-logo.JPG" alt="logo" />
            </picture>
            <div className="lg:hidden">
                <button onClick={toggleMobileMenu}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" 
                    class="w-10 h-10 bg-white p-2 rounded shadow text-[#2d3092] border border-[#2d3092]">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </button>
            </div>
            <nav className="hidden lg:block">
                <div className="lg:flex lg:items-center gap-5">
                    <NavLink to="/"  
                        className={({ isActive }) =>isActive ? "text-base text-[#1c1f65] p-2 font-bold underline" : "text-base text-gray-800 p-2 font-semibold"}
                    >
                        {t('home')}
                    </NavLink>
                    <NavLink to="/about" 
                        className={({ isActive }) =>isActive ? "text-base text-[#1c1f65] p-2 font-bold underline" : "text-base text-gray-800 p-2 font-semibold"}
                    >
                        {t('about')}
                    </NavLink>
                    {/* <NavLink to="/charity" className="text-base text-gray-900 p-2 font-semibold">{t('charity')}</NavLink> */}
                    <NavLink to="/gallery" className={({ isActive }) =>isActive ? "text-base text-[#1c1f65] p-2 font-bold underline" : "text-base text-gray-800 p-2 font-semibold"}>{t('gallery')}</NavLink>
                    <NavLink to="/donate" className={({ isActive }) =>isActive ? "text-base text-[#1c1f65] p-2 font-bold underline" : "text-base text-gray-800 p-2 font-semibold"}>{t('donate')}</NavLink>
                    <NavLink to="/contact" className={({ isActive }) =>isActive ? "text-base text-[#1c1f65] p-2 font-bold underline" : "text-base text-gray-800 p-2 font-semibold"}>{t('contact')}</NavLink>
                    <NavLink to="/requestPrayer" className={({ isActive }) =>isActive ? "text-base text-[#1c1f65] p-2 font-bold underline" : "text-base text-gray-800 p-2 font-semibold"}>{t('requestPrayer')}</NavLink>
                     {/* Language Switcher Dropdown */}
                     <div className="relative">
                        <select 
                            onChange={(e) => changeLanguage(e.target.value)}
                            className="text-base p-2 bg-white border border-gray-300 rounded">
                            <option value="en">English</option>
                            {/* <option value="te">తెలుగు</option> */}
                        </select>
                    </div>
                </div>
            </nav>
        </div>

        {isMobileMenuOpen && (
                    <div
                        onClick={closeMobileMenu}
                        className="fixed inset-0 bg-black opacity-50 z-40"
                    ></div>
                )}

                {/* Mobile Menu */}
                {isMobileMenuOpen && (
                    <nav className="lg:hidden absolute top-0 left-0 w-full bg-white p-6 shadow-lg rounded-lg z-50">
                        <div className="flex flex-col gap-6">
                            <button
                                onClick={closeMobileMenu}
                                className="self-end text-gray-500"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    className="w-8 h-8"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                            <NavLink
                                to="/"
                                className={({ isActive }) =>isActive ? "text-base text-[#1c1f65] p-2 font-bold underline" : "text-base text-gray-800 p-2 font-semibold"}
                                onClick={closeMobileMenu} // Close the menu when clicked
                            >
                                {t('home')}
                            </NavLink>
                            <NavLink
                                to="/about"
                                className={({ isActive }) =>isActive ? "text-base text-[#1c1f65] p-2 font-bold underline" : "text-base text-gray-800 p-2 font-semibold"}
                                onClick={closeMobileMenu}
                            >
                                {t('about')}
                            </NavLink>
                            <NavLink
                                to="/gallery"
                                className={({ isActive }) =>isActive ? "text-base text-[#1c1f65] p-2 font-bold underline" : "text-base text-gray-800 p-2 font-semibold"}
                                onClick={closeMobileMenu}
                            >
                                {t('gallery')}
                            </NavLink>
                            <NavLink
                                to="/donate"
                                className={({ isActive }) =>isActive ? "text-base text-[#1c1f65] p-2 font-bold underline" : "text-base text-gray-800 p-2 font-semibold"}
                                onClick={closeMobileMenu}
                            >
                                {t('donate')}
                            </NavLink>
                            <NavLink
                                to="/contact"
                                className={({ isActive }) =>isActive ? "text-base text-[#1c1f65] p-2 font-bold underline" : "text-base text-gray-800 p-2 font-semibold"}
                                onClick={closeMobileMenu}
                            >
                                {t('contact')}
                            </NavLink>
                            <NavLink
                                to="/requestPrayer"
                                className={({ isActive }) =>isActive ? "text-base text-[#1c1f65] p-2 font-bold underline" : "text-base text-gray-800 p-2 font-semibold"}
                                onClick={closeMobileMenu}
                            >
                                {t('requestPrayer')}
                            </NavLink>

                            {/* Language Switcher Dropdown */}
                            <div className="relative mt-4">
                                <select
                                    onChange={(e) => changeLanguage(e.target.value)}
                                    className="text-lg p-3 bg-white border-2 border-[#2d3092] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#2d3092] transition-all duration-300 ease-in-out"
                                >
                                    <option value="en">English</option>
                                    {/* <option value="te">తెలుగు</option> */}
                                </select>
                            </div>
                        </div>
                    </nav>
                )}
        </section>
    )
}
export default Header