import logo from './logo.svg';
import './App.css';
import Header from './components/header';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Footer from './components/footer';
import Gallery from './pages/gallery';
import Donate from './pages/donate';
import Charity from './pages/charity';
import RequestPrayer from './pages/request-prayer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './i18n';

function App() {
  return (
    <section>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/charity' element={<Charity />}></Route>
          <Route path='/gallery' element={<Gallery />}></Route>
          <Route path='/donate' element={<Donate />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/requestPrayer" element={<RequestPrayer />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </section>
  );
}

export default App;
