import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const ChurchServiceDetails = () => {
    const { t, i18n } = useTranslation(); // Get translation function and current language
    const [churches, setChurches] = React.useState([]);

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                // Determine the language and fetch the corresponding JSON file
                const language = i18n.language; // Get the current language
                const jsonFile = language === 'te' ? '/data_te.json' : '/data_en.json';
                const response = await axios.get(jsonFile); // Fetch the JSON file
                setChurches(response.data.churches); // Adjust this line based on the actual data structure
            } catch (error) {
                console.error('Error fetching church service details:', error);
            }
        };

        fetchData();
    }, [i18n.language]); // Add i18n.language to the dependency array to refetch on language change
    console.log("churches", churches)
    return (
        <section className="bg-[#2d3092] bg-opacity-85">
            <div className="container mx-auto px-4 py-20 gap-10">
                <div className="text-center mb-10">
                    <h1 className="text-3xl font-extrabold tracking-tight leading-none text-white md:text-5xl">{t('services.title')}</h1>
                    <h2 className="mt-4 text-xl font-extrabold tracking-tight leading-none text-gray-200">{t('services.subTitle')}</h2>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
                    {Array.isArray(churches) && churches.length > 0 ? (
                        churches.map((church, index) => (
                            <div key={index} className="bg-white p-6 rounded-lg shadow-lg border border-gray-800">
                                <h3 className="text-2xl font-bold text-gray-900">{t(church.name)}</h3>
                                <ul className="mt-4 space-y-2">
                                    {church.services ? (
                                        church.services.map((service, serviceIndex) => (
                                            <li key={serviceIndex}>
                                                {t(service.title)}: <span className="font-semibold">{service.time}</span>
                                            </li>
                                        ))
                                    ) : (
                                        <li>{t('no_services_available')}</li> // Handle case with no services
                                    )}
                                </ul>
                            </div>
                        ))
                    ) : (
                        <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-800 col-span-1 md:col-span-2 lg:col-span-3">
                            <p className="text-gray-900">{t('loading_data')}</p>
                        </div>
                    )}
                  
                </div>
            </div>
        </section>
    );
};

export default ChurchServiceDetails;
