import React from "react";
import { useTranslation } from "react-i18next";

const Donate = () => {
    const { t } = useTranslation();
    const divStyle = {
        backgroundImage: 'url(../../images/donate.jpg)',
        backgroundSize: 'cover',
        width: '100vw',
    };
    return (
        <>
            <div className="bg-img" style={divStyle}>
                <section className="bg-[#2d3092] bg-opacity-70">
                    <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
                        <a href="#" className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-100 bg-[#1c1f65] rounded-full">
                            <span className="text-xs bg-primary-600 rounded-full  px-4 py-1.5 mr-3">Blessing to you</span> <span className="text-sm font-medium">Prays the lord</span>
                            <svg className="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                        </a>
                        <div className="relative px-4 mx-auto max-w-screen-md text-gray-200 text-center lg:py-16 lg:px-12">
                            <blockquote className="italic text-lg md:text-2xl lg:text-3xl font-semibold">
                                "{t("donate.bibleVerse", "Each one must give as he has decided in his heart, not reluctantly or under compulsion, for God loves a cheerful giver. - 2 Corinthians 9:7")}"
                            </blockquote>
                            <p className="mt-6 text-md font-normal text-gray-200 lg:text-xl sm:px-16 xl:px-32">
                                {t("donate.introMessage", "Your contributions allow us to spread love, hope, and faith in our community. Thank you for your generous support.")}
                            </p>
                        </div>
                    </div>
                </section >
            </div >
            {/* Donation Details Section */}
            < section >
                <div className="py-8 px-4 max-w-7xl mx-auto text-center lg:py-16 lg:px-12">
                    <h2 className="text-2xl text-gray-900 font-bold tracking-wide uppercase pb-6">
                        {t("donate.howToDonate", "How to Donate")}
                    </h2>

                    <div className="grid md:grid-cols-2 gap-12">
                        {/* GPay and PhonePe Card */}

                        <div className="bg-white shadow-lg rounded-lg p-6">
                            <h3 className="text-lg font-semibold text-gray-900 pb-2">GPay & PhonePe</h3>
                            <p className="text-sm text-gray-700 mb-4">
                                {t("donate.scanQRCode", "Scan the QR code or use the following number to donate via GPay or PhonePe:")}
                            </p>
                            <div className="text-lg font-bold text-blue-800 mb-4">7416910979</div>
                            <div className="flex justify-center">
                                <img
                                    src="../../images/scanner.JPG"
                                    alt="GPay & PhonePe QR Code"
                                    className="w-48 h-48 object-cover rounded-lg shadow-md"
                                />
                            </div>
                        </div>

                        {/* Bank Transfer Card */}
                        <div className="bg-white shadow-lg rounded-lg p-6">
                            <h3 className="text-lg font-semibold text-gray-900 pb-2">Bank Transfer</h3>
                            <p className="text-sm text-gray-700 mb-4">
                                {t("donate.bankTransfer", "You can also make a direct bank transfer using the following details:")}
                            </p>
                            <ul className="text-left space-y-2">
                                <li><strong>{t("donate.bankName", "Bank Name")}:</strong> Bank of Baroda</li>
                                <li><strong>{t("donate.branch", "Branch")}:</strong> Vanukuru Branch</li>
                                <li><strong>{t("donate.accNumber", "Account Number")}:</strong> 6059 8100 0009 28 </li>
                                <li><strong>{t("donate.ifsc", "IFSC Code")}:</strong> BARB0VANUKU</li>
                            </ul>
                        </div>
                    </div>

                    {/* Closing Note */}
                    <div className="mt-12">
                        <p className="text-sm text-gray-600 italic">
                            {t("donate.thankYou", "God bless you.")}
                        </p>
                    </div>
                </div>
            </section >
        </>
    );
};

export default Donate;
