import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <section className="bg-gray-900 p-4">
            <div className="container mx-auto px-4 py-10">
                <div className="grid lg:grid-cols-3 gap-5">
                    <div>
                        <picture>
                            <img class="h-16" src="../../images/nbgc-logo.JPG" alt="logo" />
                        </picture>
                        <div className="text-gray-500 font-base mt-4">
                            <p > Thankyou for your generosity. Having trouble giving? </p>
                            <p>Click here to give or call <a href="" className="font-semibold text-[#FFD100]">+91 7416910979</a></p>
                        </div>
                    </div>
                    <div>
                        <h1 class="mb-4 text-2xl font-extrabold tracking-tight leading-none text-white">Quick Links</h1>

                        <ul className="text-gray-500 font-base mt-4 space-y-2">
                            <li><Link to="/" className="text-base text-gray-500 p-2 font-semibold">Home</Link></li>
                            <li><Link to="/about" class="text-base text-gray-500 p-2 font-semibold">About</Link></li>
                            <li><Link to="/gallery" class="text-base text-gray-500 p-2 font-semibold">Gallery</Link></li>
                            <li><Link to="/donate" class="text-base text-gray-500 p-2 font-semibold">Donate</Link></li>
                            <li><Link to="/contact" class="text-base text-gray-500 p-2 font-semibold">Contact</Link></li>
                            <li><Link to="/requestPrayer" class="text-base text-gray-500 p-2 font-semibold">Reequest Prayer</Link></li>
                        </ul>
                    </div>
                    <div>
                        <h1 class="mb-4 text-2xl font-extrabold tracking-tight leading-none text-white">About</h1>

                        <ul className=" font-base mt-4 space-y-2">
                            <li><Link  className="text-base text-gray-500 p-2 font-semibold">Privacy Policy</Link></li>
                            <li><Link class="text-base text-gray-500 p-2 font-semibold">Terms of Service</Link></li>
                        </ul>
                    </div>
                </div>
                <hr className="my-8 text-gray-500"></hr>
                <p className="text-gray-500 text-center">© New Bethesda Gospel Center 2024</p>
            </div>
        </section>
    )
}
export default Footer