import React from "react";
import { useTranslation } from "react-i18next";

const RequestPrayer = () => {
    const { t } = useTranslation();

    const divStyle = {
        backgroundImage: 'url(../../images/new-pics/IMG_0871.JPG)',
        backgroundSize: 'cover',
        width: '100vw',
    };

    const prayerDetails = [
        { title: "For Family", image: "../../images/prayer/for-family.jpg" },
        { title: "For Job", image: "../../images/prayer/for-job.jpg" },
        { title: "For Exams", image: "../../images/prayer/for-exams.jpg" },
        { title: "Financial Problems", image: "../../images/prayer/financial.jpg" },
        { title: "Spiritual Guidance", image: "../../images/prayer/spiritual.jpg" },
        { title: "For Marriage", image: "../../images/prayer/marriage.jpg" },
        { title: "For Children", image: "../../images/prayer/children.jpg" },
        { title: "For Business", image: "../../images/prayer/business.jpg" },
        { title: "For Emergency", image: "../../images/prayer/emergency.jpg" },
    ];

    return (
        <>
        <div className="bg-img" style={divStyle}>
            <section className="bg-[#2d3092] bg-opacity-70">
                <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
                    <a href="#" className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-100 bg-[#1c1f65] rounded-full">
                        <span className="text-xs bg-primary-600 rounded-full  px-4 py-1.5 mr-3">Blessing to you</span> <span className="text-sm font-medium">Prays the lord</span>
                        <svg className="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                    </a>
                    <h1 className="mb-4 text-3xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">"For where two or three gather in my name, there am I with them."</h1>
                    <p className="mb-8 text-md font-normal text-gray-300 leading-5 lg:text-xl sm:px-16 xl:px-48"> 
                         - Matthew 18:20
                    </p>
                    <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                        <a href="#" className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700">
                            Know more
                            <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </a>
                        <a href="https://youtu.be/Lplx4x5UBao?si=RpGdaRBvH1-QpW35" target="_blank" className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg border border-gray-100 ">
                            <svg className="mr-2 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path></svg>
                            Watch video
                        </a>
                    </div>
                   
                </div>
            </section>
        </div>
            <section className="bg-gray-50 py-16">
                <div className="max-w-6xl mx-auto px-6">
                    {/* Prayer Section Title */}
                    <h2 className="text-center text-3xl font-bold text-gray-800 mb-8">
                        {t("prayer.categoriesTitle", "How Can We Pray for You?")}
                    </h2>
                    {/* Problems as Cards */}
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {prayerDetails.map((item, idx) => (
                           <div
                           key={idx}
                           className="p-6 bg-white rounded-lg shadow-md text-center overflow-hidden"
                       >
                           {/* Image Section */}
                           <div className="relative h-40 w-full overflow-hidden rounded-t-lg">
                               <img
                                   src={item.image}
                                   alt={item.title}
                                   className="w-full h-full object-cover"
                               />
                           </div>
                           {/* Text Section */}
                           <div className="py-4 px-4">
                               <h3 className="mt-2 text-xl font-semibold text-gray-900">
                                   {t(`prayer.${item.title}`, item.title)}
                               </h3>
                               
                           </div>
                       </div>
                       
                        ))}
                    </div>
                </div>
                {/* Contact Section */}
                <div className="mt-16">
                    <h2 className="text-center text-2xl font-bold text-gray-800 mb-6">
                        {t("contact.title", "Contact Us")}
                    </h2>
                    <div className="text-center">
                        <p className="text-lg text-gray-700">
                            {t(
                                "contact.email",
                                "Email us at: nbgc1717@gmail.com"
                            )}
                        </p>
                        <p className="text-lg text-gray-700">
                            {t("contact.phone", "Call us at: +91 7416910979")}
                        </p>
                    </div>
                </div>
            </section></>
    );
};

export default RequestPrayer;
